import { billingService, customerService, todoTaskService, workflowDesignService } from '@/api';
import OsQueryPlan, { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { BillingStatusEnum, PageQueryPlanEnum } from '@/resource/enum';
import { BillingList, BillingListQuery, CustomerResource, WorkflowDesignResource } from '@/resource/model';
import { dateFormat, debounce, downloadFileByBlob, messageError, showWarningConfirm, translation } from '@/utils';
import { Decimal } from 'decimal.js';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { InitiateApproval } from '@/views/dialogs';

@Component({
  name: 'Billing',
  components: { InitiateApproval }
})
export default class Billing extends mixins(PagingMixin, CustomColumnMixin) {
  public approvalDialogVisible: boolean = false;
  public workflowDesign: WorkflowDesignResource = {} as WorkflowDesignResource;
  public tableOption: OsTableOption<BillingList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<BillingList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'billCode',
      label: 'billing.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'billing.customer',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'currencyName',
      label: 'billing.settleCurrency',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'exchangeRate',
      label: 'billing.settleExchangeRate',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as BillingList).exchangeRate.toFixed(6);
      }
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.billAmount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as BillingList).amountExcludingTax.toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.writeOffTotalAmount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as BillingList).amountIncludingTax.toFixed(2);
      }
    },
    {
      prop: 'writeOffAmount',
      label: 'billing.amountWrittenOff',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as BillingList).writeOffAmount.toFixed(2);
      }
    },
    {
      prop: 'unWrittenAmount',
      label: 'billing.amountToBeWrittenOff',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return new Decimal((rowData as BillingList).amountIncludingTax)
          .sub(new Decimal((rowData as BillingList).writeOffAmount))
          .toFixed(2);
      }
    },
    {
      prop: 'invoiceTotal',
      label: 'billing.invoiceRecord',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return `
          ${(rowData as BillingList).invoicedCount}/
          ${(rowData as BillingList).invoiceTotal}`;
      }
    },
    {
      prop: 'status',
      label: 'billing.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'finishDate',
      label: 'billing.finishTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as BillingList).finishDate);
      }
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as BillingList).createTime);
      }
    },
    {
      prop: 'remark',
      label: 'supplier.remark',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'billing.customer',
      option: {
        placeholder: 'billing.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus',
        multiple: true
      },
      optionData: billingService.getStatus
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      option: {
        placeholder: 'common.inputCreateUserName'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'common.createTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'finance:bill:save',
      handleClick: this.LinkToAddPage
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.submit',
      operationType: 'submit',
      disabled: true,
      icon: 'el-icon-check',
      permissionCode: 'finance:bill:submit',
      handleClick: this.submitApply
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.cancel',
      operationType: 'cancel',
      disabled: true,
      plain: true,
      icon: 'el-icon-caret-left',
      permissionCode: 'finance:bill:withdrawn',
      handleClick: this.cancelApply
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'billing.applyAbolish',
      operationType: 'applyAbolish',
      icon: 'el-icon-close',
      permissionCode: 'finance:bill:repeal',
      plain: true,
      disabled: true,
      handleClick: this.repeal
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'finance:bill:deleteBillDetail',
      plain: true,
      disabled: true,
      handleClick: this.batchDelete
    },
    {
      slot: 'start',
      label: 'billing.exportBill',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'finance:bill:export',
      plain: true,
      handleClick: this.exportBill
    },
    {
      slot: 'start',
      label: 'billing.exportLedger',
      operationType: 'exportLedger',
      icon: 'el-icon-download',
      permissionCode: 'finance:bill:exportLedger',
      plain: true,
      handleClick: this.exportLedger
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<BillingList> = {
    fixed: 'right',
    width: '150px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'finance:bill:edit',
        dynamicHidden: (rowData: BillingList): boolean => {
          return rowData.status !== BillingStatusEnum.new && rowData.status !== BillingStatusEnum.failedAudit;
        },
        handleClick: (item: BillingList): void => {
          this.$router.push({
            path: 'add-billing',
            query: {
              id: item.id.toString()
            }
          });
        }
      },
      {
        operationType: 'copy',
        type: 'text',
        label: 'button.copy',
        icon: 'el-icon-document-copy',
        permissionCode: 'finance:bill:copy',
        handleClick: (item: BillingList): void => {
          this.$router.push({
            path: 'add-billing',
            query: {
              id: item.id.toString(),
              type: 'isCopy'
            }
          });
        }
      }
    ]
  };

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.billing;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  public businessId: number = 0;

  public billing: BillingList | null = null;

  public customers: Array<CustomerResource> = [];

  public selectedRows: Array<BillingList> = [];

  private queryForm: Partial<BillingListQuery> & {
    createTime: Array<string>;
  } = {
    keywords: '',
    statusList: [],
    customerId: undefined,
    createUserName: '',
    createTime: []
  };

  private defaultQueryForm: Partial<BillingListQuery & { createTime: Array<string> }> = {
    createTime: [],
    createUserName: '',
    customerId: undefined,
    keywords: '',
    statusList: []
  };

  public activated(): void {
    if (this.$route.query.keywords) {
      // 路由携带查询条件参数，就路由的为主， 新建、编辑返回查询条件保持跳转之前状态
      Object.assign(this.defaultQueryForm, {
        keywords: (this.$route.query.keywords as string) || ''
      });
      Object.assign(this.queryForm, this.defaultQueryForm);
      this.$router.push({ query: {} });
    }
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'billing');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.clearSelection();
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public linkToDetails(billing: BillingList): void {
    this.$router.push({
      path: 'billing-details',
      query: {
        id: billing.id.toString()
      }
    });
  }

  public handleSelectionChange(selectedData: Array<BillingList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusClass(billing: BillingList): string {
    switch (billing.status) {
      case BillingStatusEnum.new:
        return 'info-dot';
      case BillingStatusEnum.waitAudit:
        return 'warning-dot';
      case BillingStatusEnum.waitMakeInvoice:
        return 'primary-dot';
      case BillingStatusEnum.waitCancelAfterVerification:
        return 'primary-dot';
      case BillingStatusEnum.failedAudit:
        return 'danger-dot';
      case BillingStatusEnum.complete:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(billing: BillingList): string {
    return BillingStatusEnum[billing.status]
      ? `billingStatus.${BillingStatusEnum[billing.status]}`
      : 'common.unKnownStatus';
  }

  public showAuditFailedReason(status: BillingStatusEnum): boolean {
    return status === BillingStatusEnum.failedAbolishAudit || status === BillingStatusEnum.failedAudit;
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<BillingList>): void {
    const notControl = ['add', 'export', 'exportLedger'];
    this.operationOptions.forEach(x => {
      if (!notControl.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.billingTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private loadData(): void {
    this.tableOption.loading = true;
    billingService
      .getList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private LinkToAddPage(): void {
    this.$router.push({
      path: 'add-billing'
    });
  }

  /**
   * 申请废结
   */
  private async repeal(): Promise<void> {
    if (this.selectedRows.length > 1) {
      Message.warning(translation('common.selectMaxDataTip'));
      return;
    }
    this.workflowDesign = (await workflowDesignService.getByTag('billRepealHandler')) ?? {};
    if (this.workflowDesign.id) {
      this.businessId = this.selectedRows[0].id;
      this.approvalDialogVisible = true;
    } else {
      this.$message.warning('当前业务没有设置审批任务');
    }
  }
  private async submitApply(): Promise<void> {
    if (this.selectedRows.length > 1) {
      Message.warning(translation('common.selectMaxDataTip'));
      return;
    }
    this.workflowDesign = (await workflowDesignService.getByTag('billTaskHandler')) ?? {};
    if (this.workflowDesign.id) {
      this.businessId = this.selectedRows[0].id;
      this.approvalDialogVisible = true;
    } else {
      this.$message.warning('当前业务没有设置审批任务');
    }
  }

  /**
   * 取消审核
   */
  private async cancelApply(): Promise<void> {
    try {
      if (this.selectedRows.length > 1) {
        Message.warning(translation('common.selectMaxDataTip'));
        return;
      }
      const allowStatus = [
        BillingStatusEnum.waitAudit,
        // BillingStatusEnum.failedAudit,
        BillingStatusEnum.abolishAudit
        // BillingStatusEnum.failedAbolishAudit
      ];
      const ids: Array<number> = this.selectedRows.filter(x => allowStatus.includes(x.status)).map(x => x.id);

      if (ids.length === 0) {
        Message.warning(translation('billing.cancelApplyNoStandard'));
        this.clearSelection();
        return;
      }

      await showWarningConfirm(translation('billing.confirmCancelApply'));
      this.tableOption.loading = true;

      const params = {
        businessId: this.selectedRows[0].id,
        tag: ''
      };
      if (this.selectedRows[0].status === BillingStatusEnum.abolishAudit) {
        params.tag = 'billRepealHandler';
      } else {
        params.tag = 'billTaskHandler';
      }

      await todoTaskService.withdrawn(params);
      this.loadData();
      this.clearSelection();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelOperation'));
        return;
      }
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 导出单据
   */
  private async exportBill(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const exportQuery = {
        idList: this.selectedRows.map(x => x.id),
        ...this.queryForm
      };
      const blob = await billingService.export(exportQuery);
      downloadFileByBlob(`${translation('billing.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
      this.clearSelection();
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 导出台账
   */
  private async exportLedger(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const exportQuery = {
        idList: this.selectedRows.map(x => x.id),
        ...this.queryForm
      };
      const blob = await billingService.exportLedger(exportQuery);
      downloadFileByBlob(`${translation('billing.exportLedgeFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async batchDelete(): Promise<void> {
    try {
      await showWarningConfirm(translation('tip.confirmDelete'));
      this.tableOption.loading = true;
      const idList: Array<number> = this.selectedRows.filter(x => x.status === BillingStatusEnum.new).map(x => x.id);
      if (idList.length === 0) {
        Message.warning(translation('billing.operationNoStandard'));
        this.clearSelection();
        return;
      }

      await billingService.batchDelete(idList);
      this.reloadData();
      Message.success(translation('operationRes.deleteSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelDelete'));
        return;
      }
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
